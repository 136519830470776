const ARUBA_API_GET_CLIENT_ID_URL = 'https://portal.arubainstanton.com/settings.json';
const ARUBA_API_GET_TEMP_ACCESS_TOCKEN_URL = 'https://sso.arubainstanton.com/aio/api/v1/mfa/validate/full'
const ARUBA_API_GET_AUTHORI_CODE_URL = 'https://sso.arubainstanton.com/as/authorization.oauth2';
const ARUBA_API_GET_ACTUAL_ACCESS_TOCKEN_URL = 'https://sso.arubainstanton.com/as/token.oauth2'
const ARUBA_API_REDIRECT_URI = 'https://portal.arubainstanton.com';
const ARUBA_API_GET_SITE_URL = 'https://nb.portal.arubainstanton.com/api/sites';
const ARUBA_CODE_VERIFIER = 'ukON5pKO194Yqs_yJWnQvtxOK8ukON5pKO194Yqs_yJWnQvtxOK8ukON5pKO194Yqs_yJWnQvtxOK8';
const ARUBA_CODE_CHALLENGE = 'sVOx3dcN-l20hu7GxBEW6gNnwgJSx8u9pYwUB4V8lXk';

const config = {
    ARUBA_API_GET_CLIENT_ID_URL,
    ARUBA_API_GET_CLIENT_ID_URL,
    ARUBA_API_GET_TEMP_ACCESS_TOCKEN_URL,
    ARUBA_API_GET_AUTHORI_CODE_URL,
    ARUBA_API_GET_ACTUAL_ACCESS_TOCKEN_URL,
    ARUBA_API_GET_SITE_URL,
    ARUBA_API_REDIRECT_URI,
    ARUBA_CODE_VERIFIER,
    ARUBA_CODE_CHALLENGE
}
export default config 
