import axios from 'axios'
import configs from '../utils/config.js'

const register = (username, email, password) => {
  return axios.post(configs.ARUBA_API_GET_SITE_URL, {
    username,
    email,
    password,
  })
}


  const login = async (username, password) => {
    // 1. Get client_id from local storage
    let client_id = getClientId();
    if (!client_id) {
      client_id = await fetchClientId();
    }

    // 2. Get temporary access token
    const tempAccessToken = await getTempAccessToken(username, password);

    // 3. Get authorization code
    const authorizationCode = await getAuthorizationCode(client_id, tempAccessToken);

    // 4. Get actual access token
    const actualAccessToken = await getActualAccessToken(client_id, authorizationCode);

    return actualAccessToken;
  }

  const getTempAccessToken = (username, password) => {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);
    params.append('grant_type', 'password');

    return axios
      .post(configs.ARUBA_API_GET_TEMP_ACCESS_TOCKEN_URL, params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((response) => {
        console.log(response.data);
        return response.data.access_token;
      })
      .catch((error) => {
        if(error.response.status === 400) {
          throw new Error(error.response.data.error_description);
        }
        throw error;
      });
  }

  const getAuthorizationCode = (client_id, tempAccessToken) => {
    return axios.get(configs.ARUBA_API_GET_AUTHORI_CODE_URL, {
        params: {
          response_type: 'code',
          client_id: client_id,
          scope: 'profile openid',
          redirect_uri: configs.ARUBA_API_REDIRECT_URI,
          resource: '0003t2QvsC4xdpnwwlhh1KMhlVC2',
          code_challenge: configs.ARUBA_CODE_CHALLENGE,
          code_challenge_method: 'S256',
          sessionToken: tempAccessToken,
        }
      })
      .then((response) => {
        console.log(response);
        const locationUrl = response.request.responseURL; 
        const authorizationCode = new URL(locationUrl).searchParams.get('code');
        return authorizationCode;
      })
      .catch((error) => {

        return error;
      });
  }

  const getActualAccessToken = (client_id, authorizationCode) => {
    const params = new URLSearchParams();
    params.append('redirect_uri', configs.ARUBA_API_REDIRECT_URI);
    params.append('client_id', client_id);
    params.append('code_verifier', configs.ARUBA_CODE_VERIFIER);
    params.append('grant_type', 'authorization_code');
    params.append('code', authorizationCode);

    return axios
      .post(configs.ARUBA_API_GET_ACTUAL_ACCESS_TOCKEN_URL, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.access_token) {
          setProfile(response.data.access_token);
          localStorage.setItem('user', JSON.stringify(response.data));
        }
        return response.data.access_token;
      })
      .catch((error) => {
        if(error.response.status === 400) {
          throw new Error(error.response.data.error);
        }
        throw error;
      });
  }

const logout = () => {
  localStorage.removeItem('user')
}
const setProfile = (profile) => {
  sessionStorage.setItem("token", profile);
}
const getProfile = () => {
  let user = JSON.parse(localStorage.getItem("user"));
  if (user) {
    const profile = sessionStorage.getItem("token");
    return profile ? profile : null;
  } else {
    return null;
  }
}

const setClientID = (client_id) => {
  localStorage.setItem("client_id", client_id);
}

const getClientId = () => {
  let client_id = localStorage.getItem("client_id");
  return client_id ? client_id : null;
}
const fetchClientId = () => {
  return axios
    .get(configs.ARUBA_API_GET_CLIENT_ID_URL)
    .then((response) => {
      console.log(response.data)
      if (response.data.ssoClientIdAuthZ) {
        setClientID(response.data.ssoClientIdAuthZ);
        return response.data.ssoClientIdAuthZ;
      }
      return ''; 
    });
};

const auth = {
  register,
  login,
  logout,
  getProfile,
  getClientId,
  fetchClientId,
}
export default auth
